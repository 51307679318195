import React, { useState } from "react";
import cn from "classnames";
import { reachGoal } from "components/YandexMetrika";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

const ExternalReview = ({utm= null, comment= '', adplatforms = [] }) => {

    const [visible, setVisible] = useState(false)

    const onHide = () => {
        setVisible(false);
    }

    const acceptFunc = (e) => {
        e.preventDefault()
        e.stopPropagation();

        try {
            const platform = adplatforms.find(item => item.platform === 'otzovik')

            const link = platform.url;
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = link;
            a.target = "_blank";
            a.click();
            document.body.removeChild(a);

        }
        catch (e) { }

        onHide()
    }

    const renderFooter = () => {
        return (
            <div style={{'textAlign': 'center'}}>
                <Button label="Понятно" icon="pi pi-check" onClick={acceptFunc} className={cn('acceptButtonClass')}/>
            </div>
        );
    }

    const showModal = () => {
        setVisible(true)
    }

    const copyToClipboard = (text) => {
        reachGoal('copy2go_event');

        let textarea = document.createElement('textarea');
        document.body.appendChild(textarea);
        textarea.value = text;
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
    }

    const sendSomething = (platform) => {
        let data = new FormData();
        data.append('application', utm);
        data.append('adplatform', platform);
        navigator.sendBeacon(`${process.env.REACT_APP_API_URL}/review/ad-tracker`, data);
    }

    return (
        <div className={cn('external-review__images')}>

            <Dialog
                visible={visible}
                onHide={onHide}
                breakpoints={{'960px': '75vw', '640px': '100vw'}}
                style={{width: '40vw'}}
                footer={renderFooter}
                showHeader={false}
            >
                <p>Обратите внимание, что для публикации отзыва на площадке <b>Отзовик</b> необходимо выбрать <b>Способ приобретения: "Купили / Есть в свободном доступе (бесплатно)"</b></p>
                <p>Иначе Ваш отзыв не будет опубликован на площадке.</p>
            </Dialog>

            <div className={'adplatforms'}>
                {adplatforms.map((item, key) =>
                    (
                        <a
                            aria-label={item.url}
                            key={key}
                            href={item.url}
                            className={cn('item', item.class)}
                            onClick={(e) => {
                                sendSomething(item.platform);
                                copyToClipboard(comment);

                                if(item.platform === 'otzovik') {
                                    showModal(item.platform)
                                    e.preventDefault()
                                }

                            }}
                            target="_blank"
                            rel="noreferrer"
                        />
                    )
                )}
            </div>
        </div>
    )
}

export default ExternalReview;
