import cn from "classnames";
import {getLogo} from "../../services/logo";
import {useEffect, useState} from "react";

const Header = ({template = 'default'}) => {
    const [logo, setLogo] = useState(null);
    useEffect(() => {
        const logo = getLogo(template)
        setLogo(logo);
    }, []);

    return (
        <>
            {logo && <div className={'header'}>
                <div className={'header__wrapper'}>
                    <div className={cn('header__logo')}>
                        <a href='/'>
                            <img src={logo.src} alt="" height={logo.height}/>
                        </a>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default Header;