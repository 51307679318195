import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import cn from "classnames";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { mainService } from "../services";
import Agreement from "./common/Agreement";
import RatingVariantWay from "./common/RatingVariantWay";
import Footer from "./common/Footer";
import PhoneInput from "./common/PhoneInput";
import Header from "./common/Header";
import { FormHeader } from "./tmp_folder/Templates";
import { getParam } from "./helpers";
import FormFooter from "./common/FormFooter";
import { Dialog } from 'primereact/dialog';

//TODO убрать в справочник
//"avito" "google" "yandex" "2gis" "otzovik" "flamp" "irecommend" "vkontakte" "zoon"

const variants = [
    {value: 1, title: 'Все отлично!', ico: '', active: false},
    {value: -1, title: 'Есть претензия', ico: '', active: false},
];

const Qr = ({template = 'default'}) => {
    const utm = getParam();
    let history = useHistory();

    const [branch, setBranch] = useState('');
    const [loading, setLoading] = useState(false);
    const [rating, setRating] = useState(0);
    const [adplatforms, setAdplatforms] = useState([]);
    const [comment, setComment] = useState('');
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [agreement, setAgreement] = useState(false);

    const { destOptions, prepareHeader } = mainService;

    useEffect(() => {
        setIsValid((
            !!comment.length &&
            !!agreement
        ));
    }, [comment, agreement]);

/*    useEffect(()=>{
        let adplatformsArr = [];
        destOptions.map(item => {
            adplatformsArr.push({
                ...item,
                url: 'item.url'
            })
        })
        setAdplatforms(adplatformsArr);
    }, [])*/

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/review/qr/branch/${utm}`, { method: 'GET' })
            .then(response => response.json())
            .then(result => {
                if(result.data) {
                    setBranch(result.data.branch)

                    let adplatformsArr = [];
                    result.data?.adplatforms.forEach(item => {
                        const dest = destOptions.find((dest) => dest.platform === item.type);
                        if (typeof dest !== 'undefined') {
                            adplatformsArr.push({
                                ...dest,
                                url: item.url
                            })
                        }
                    });

                    adplatformsArr.sort((a, b) => a.sort > b.sort ? 1 : -1);

                    setAdplatforms(adplatformsArr);
                }
            });
    }, [utm]);

    useEffect(() => {
        setIsValid((
            !!phone.length &&
            !!comment.length &&
            !!agreement
        ));
    }, [comment, agreement, phone]);

    const sentPositive = (platform = '') => {
        let data = new FormData();
        data.append('adplatform', platform);
        navigator.sendBeacon(`${process.env.REACT_APP_API_URL}/review/qr/branch/${utm}/transition`, data);
    }

    const onSubmit = () => {

        fetch(`${process.env.REACT_APP_API_URL}/review/qr/branch/${utm}/feedback`,
            prepareHeader({
                "comment": comment,
                "phone": phone,
            }))
            .then(response => response.json())
            .then(result => {
                if(result.statusCode === 200) {
                    history.push(`/claim_accepted`)
                }
                if (result.statusCode === 422 && !!result.error.data.length) {
                    let errorArray = []
                    result.error.data.map((error) => errorArray.push(error.message));
                    setErrors(errorArray);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const [visible, setVisible] = useState(false)

    const onHide = () => {
        setVisible(false);
    }

    const acceptFunc = (e) => {
        e.preventDefault()
        e.stopPropagation();

        try {
            const platform = adplatforms.find(item => item.platform === 'otzovik')

            const link = platform.url;
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = link;
            a.target = "_blank";
            a.click();
            document.body.removeChild(a);

        }
        catch (e) {

        }

        onHide()
    }

    const renderFooter = () => {
        return (
            <div style={{'textAlign': 'center'}}>
                <Button label="Понятно" icon="pi pi-check" onClick={acceptFunc} className={cn('acceptButtonClass')}/>
            </div>
        );
    }

    const showModal = () => {
        setVisible(true)
    }

    return (
        <>
            <Header template={template}/>

            <Dialog
                visible={visible}
                onHide={onHide}
                breakpoints={{'960px': '75vw', '640px': '100vw'}}
                style={{width: '40vw'}}
                footer={renderFooter}
                showHeader={false}
            >
                <p>Обратите внимание, что для публикации отзыва на площадке <b>Отзовик</b> необходимо выбрать <b>Способ приобретения: "Купили / Есть в свободном доступе (бесплатно)"</b></p>
                <p>Иначе Ваш отзыв не будет опубликован на площадке.</p>
            </Dialog>


            <div className={cn('bg__wrapper')}>
                <div className={cn('container', 'qr')}>
                    <div className={cn('form')}>
                        <FormHeader template={template} branch={branch}/>
                        <div className={cn('form__container')}>
                            {loading && <div className={cn('loader')}><ProgressSpinner /></div>}
                            {rating === 0 &&<div className={'element__wrapper_fake'}>
                                <h3>Подскажите, как все прошло?</h3>
                                <div>
                                    <RatingVariantWay
                                        variants={variants}
                                        onChange={(value) => {
                                            setRating(value)
                                        }}
                                    />
                                </div>
                            </div>}
                            {(rating === 1) && <div className={'external-review__wrapper'}>
                                <div className={'spacer'} />
                                <div className={cn('external-review')}>
                                    <h3>Будем признательны, если Вы оставите отзыв на одном из сайтов:</h3>
                                    <div className={'external-review__subtext'}>Выберите площадку, чтобы перейти на нее.
                                    </div>
                                    {adplatforms && <div className={cn('external-review__images')}>
                                        <div className={'adplatforms'}>
                                            {adplatforms.map((item, key) => {
                                                return <a
                                                        aria-label={item.url}
                                                        key={key}
                                                        href={item.url}
                                                        className={cn('item', item.class)}
                                                        onClick={(e) => {
                                                            if(item.platform === 'otzovik') {
                                                                showModal(item.platform)
                                                                e.preventDefault()
                                                            }

                                                            sentPositive(item.platform)

                                                        }}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                </a>

                                            })}
                                        </div>
                                    </div>}
                                </div>
                            </div>}
                            {rating === -1 && <>
                                <div className={'element__wrapper'}>
                                    <div className={'spacer'} />
                                    <h3>Напишите, пожалуйста, что Вам не понравилось и что нужно сделать лучше</h3>
                                    <div className={cn('textarea__wrapper')}>
                                <textarea
                                    name="message"
                                    id="message"
                                    value={comment}
                                    placeholder='Напишите ваш отзыв...'
                                    onChange={(e) => setComment(e.target.value)}
                                />
                                    </div>
                                    <div className={cn('inputtext__wrapper')}>
                                        <PhoneInput
                                            type="tel"
                                            name="phone"
                                            id="phone"
                                            placeholder='Ваш номер телефона'
                                            value={phone} onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </div>
                                    {!!errors.length && <div className={cn('errors__wrapper')}>
                                        {errors.map((error, key) => (<div key={key}>{error}</div>))}
                                    </div>}
                                </div>

                                <div className={'button__wrapper'}>
                                    <Button
                                        label="Оставить отзыв →"
                                        onClick={onSubmit}
                                        disabled={!isValid}
                                    />
                                </div>
                                <div className={cn('agreement')}>
                                    <Agreement
                                        setAgreement={setAgreement}
                                        agreement={agreement}
                                    />
                                </div>
                            </>}

                        </div>
                        <FormFooter template={template} />
                    </div>
                </div>
                <Footer template={template} />
            </div>


        </>
    )
}

export default Qr;
